import {lazy} from "react";

export const CaseList = lazy(() => import("./pages/tutorial-cases/CaseList"));
export const Static = lazy(() => import("./pages/static/Static"));
export const Matching = lazy(() => import("./pages/static/Matching"));
export const Contact = lazy(() => import("./pages/static/Contact"));
export const Registration = lazy(() => import("./pages/tutors/Registration"));
export const Login = lazy(() => import("./pages/login/Login"));
export const ChangePassword = lazy(() => import("./pages/login/ChangePassword"));
export const Profile = lazy(() => import("./pages/tutors/ProfileContainer"));
