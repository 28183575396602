import React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route
} from "react-router-dom";
import {ThemeProvider} from "@material-ui/core/styles";
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    CaseList,
    ChangePassword,
    Contact,
    Login,
    Matching,
    Registration,
    Profile
} from "./loadable-pages";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Switch>
                    <Route exact path={"/"} component={CaseList} />
                    <Route exact path={"/matching"} component={Matching} />
                    <Route exact path={"/contact"} component={Contact} />
                    <Route exact path={"/register"} component={Registration} />
                    <Route exact path={"/login"} component={Login} />
                    <Route exact path={"/profile"} component={Profile} />
                    <Route
                        exact
                        path={"/profile/change-password"}
                        component={ChangePassword}
                    />
                    <Redirect
                        from={"*"}
                        to={{
                            pathname: "/"
                        }}
                    />
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

export default App;
